import React, { Component } from 'react'

export class Icon extends Component {
	render() {
		const { type, className  } = this.props;
		return (
			<>{React.createElement(type, { className: className })}</>
		)
	}
}

export default Icon



export const IconSchedule = (props) => {
  const {
    width = 36,
    height = 36,
    color = 'black'
  } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_256_3709)">
        <path
          d="M34.7143 6.42128H28.2857V4.17128C28.2857 3.9945 28.1411 3.84985 27.9643 3.84985H25.7143C25.5375 3.84985 25.3929 3.9945 25.3929 4.17128V6.42128H19.4464V4.17128C19.4464 3.9945 19.3018 3.84985 19.125 3.84985H16.875C16.6982 3.84985 16.5536 3.9945 16.5536 4.17128V6.42128H10.6071V4.17128C10.6071 3.9945 10.4625 3.84985 10.2857 3.84985H8.03571C7.85893 3.84985 7.71429 3.9945 7.71429 4.17128V6.42128H1.28571C0.574554 6.42128 0 6.99584 0 7.707V30.8499C0 31.561 0.574554 32.1356 1.28571 32.1356H34.7143C35.4254 32.1356 36 31.561 36 30.8499V7.707C36 6.99584 35.4254 6.42128 34.7143 6.42128ZM33.1071 29.2427H2.89286V9.31414H7.71429V11.5641C7.71429 11.7409 7.85893 11.8856 8.03571 11.8856H10.2857C10.4625 11.8856 10.6071 11.7409 10.6071 11.5641V9.31414H16.5536V11.5641C16.5536 11.7409 16.6982 11.8856 16.875 11.8856H19.125C19.3018 11.8856 19.4464 11.7409 19.4464 11.5641V9.31414H25.3929V11.5641C25.3929 11.7409 25.5375 11.8856 25.7143 11.8856H27.9643C28.1411 11.8856 28.2857 11.7409 28.2857 11.5641V9.31414H33.1071V29.2427ZM14.1429 17.3499H6.75C6.57321 17.3499 6.42857 17.4945 6.42857 17.6713V19.5999C6.42857 19.7766 6.57321 19.9213 6.75 19.9213H14.1429C14.3196 19.9213 14.4643 19.7766 14.4643 19.5999V17.6713C14.4643 17.4945 14.3196 17.3499 14.1429 17.3499ZM14.1429 22.8141H6.75C6.57321 22.8141 6.42857 22.9588 6.42857 23.1356V25.0641C6.42857 25.2409 6.57321 25.3856 6.75 25.3856H14.1429C14.3196 25.3856 14.4643 25.2409 14.4643 25.0641V23.1356C14.4643 22.9588 14.3196 22.8141 14.1429 22.8141ZM26.5259 15.6865L22.3634 21.4601L20.242 18.5231C20.1214 18.3543 19.9286 18.2579 19.7237 18.2579H17.5179C17.2567 18.2579 17.104 18.5552 17.2567 18.7682L21.8411 25.1244C21.9003 25.2065 21.9781 25.2734 22.0682 25.3196C22.1583 25.3657 22.2581 25.3898 22.3594 25.3898C22.4606 25.3898 22.5604 25.3657 22.6505 25.3196C22.7406 25.2734 22.8185 25.2065 22.8777 25.1244L29.5071 15.9356C29.6598 15.7226 29.5071 15.4253 29.246 15.4253H27.0402C26.8393 15.4213 26.6464 15.5217 26.5259 15.6865Z"
          fill={color}/>
      </g>
      <defs>
        <clipPath id="clip0_256_3709">
          <rect width="36" height="36" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
}


export const IconApplication = (props) => {
  const {
    width = 36,
    height = 36,
    color = 'black'
  } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.32237 7.46593C9.14559 7.46593 9.00094 7.61057 9.00094 7.78735V9.71593C9.00094 9.89271 9.14559 10.0374 9.32237 10.0374H24.7509C24.9277 10.0374 25.0724 9.89271 25.0724 9.71593V7.78735C25.0724 7.61057 24.9277 7.46593 24.7509 7.46593H9.32237ZM16.7152 13.2516H9.32237C9.14559 13.2516 9.00094 13.3963 9.00094 13.5731V15.5016C9.00094 15.6784 9.14559 15.8231 9.32237 15.8231H16.7152C16.892 15.8231 17.0367 15.6784 17.0367 15.5016V13.5731C17.0367 13.3963 16.892 13.2516 16.7152 13.2516ZM14.7867 31.6534H5.78666V3.36771H28.2867V16.2249C28.2867 16.4016 28.4313 16.5463 28.6081 16.5463H30.8581C31.0349 16.5463 31.1795 16.4016 31.1795 16.2249V1.76057C31.1795 1.04941 30.605 0.474854 29.8938 0.474854H4.17951C3.46835 0.474854 2.8938 1.04941 2.8938 1.76057V33.2606C2.8938 33.9717 3.46835 34.5463 4.17951 34.5463H14.7867C14.9634 34.5463 15.1081 34.4016 15.1081 34.2249V31.9749C15.1081 31.7981 14.9634 31.6534 14.7867 31.6534ZM32.4652 28.1177H26.6795V26.6472C28.5398 26.0927 29.8938 24.3731 29.8938 22.332C29.8938 19.8449 27.8809 17.832 25.3938 17.832C22.9067 17.832 20.8938 19.8449 20.8938 22.332C20.8938 24.3691 22.2478 26.0927 24.1081 26.6472V28.1177H18.3224C17.9688 28.1177 17.6795 28.407 17.6795 28.7606V34.8677C17.6795 35.2213 17.9688 35.5106 18.3224 35.5106H32.4652C32.8188 35.5106 33.1081 35.2213 33.1081 34.8677V28.7606C33.1081 28.407 32.8188 28.1177 32.4652 28.1177ZM23.3849 22.332C23.3849 21.2231 24.2849 20.3231 25.3938 20.3231C26.5027 20.3231 27.4027 21.2231 27.4027 22.332C27.4027 23.4409 26.5027 24.3409 25.3938 24.3409C24.2849 24.3409 23.3849 23.4409 23.3849 22.332ZM30.617 33.0195H20.1706V30.6088H30.617V33.0195Z"
            fill={color}/>
    </svg>
  );
}


export const IconPhoto = (props) => {
  const {
    width = 36,
    height = 36,
    color = 'black'
  } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.1427 7.40015H26.6784L25.3766 3.75193C25.2867 3.5023 25.1219 3.28651 24.9047 3.13406C24.6875 2.9816 24.4286 2.89991 24.1632 2.90015H11.8365C11.294 2.90015 10.8079 3.24166 10.6271 3.75193L9.32127 7.40015H3.85699C2.08109 7.40015 0.6427 8.83854 0.6427 10.6144V28.9359C0.6427 30.7118 2.08109 32.1502 3.85699 32.1502H32.1427C33.9186 32.1502 35.357 30.7118 35.357 28.9359V10.6144C35.357 8.83854 33.9186 7.40015 32.1427 7.40015ZM32.4641 28.9359C32.4641 29.1127 32.3195 29.2573 32.1427 29.2573H3.85699C3.6802 29.2573 3.53556 29.1127 3.53556 28.9359V10.6144C3.53556 10.4376 3.6802 10.293 3.85699 10.293H11.3583L12.0454 8.37247L12.9655 5.793H23.0302L23.9503 8.37247L24.6373 10.293H32.1427C32.3195 10.293 32.4641 10.4376 32.4641 10.6144V28.9359ZM17.9998 12.8644C14.4481 12.8644 11.5713 15.7412 11.5713 19.293C11.5713 22.8448 14.4481 25.7216 17.9998 25.7216C21.5516 25.7216 24.4284 22.8448 24.4284 19.293C24.4284 15.7412 21.5516 12.8644 17.9998 12.8644ZM17.9998 23.1501C15.8704 23.1501 14.1427 21.4225 14.1427 19.293C14.1427 17.1635 15.8704 15.4359 17.9998 15.4359C20.1293 15.4359 21.857 17.1635 21.857 19.293C21.857 21.4225 20.1293 23.1501 17.9998 23.1501Z"
            fill={color}/>
    </svg>
  );
}





import {
  HAVE_UNREAD_MESSAGES,
  UPDATE_UNREAD_MESSAGES,
  ALLOWED_HELP_DESK,
  SEARCH_VIN,
  SEARCH_VIN_AUDIT,
  PAGINATION_SETTINGS,
  PAGINATION_SETTINGS_AUDIT
} from '../../constants/HelpDesk';


export const xxx = (param1, param2) => {
    return {
        type: 'xxx',
        payload: {param1, param2}
    };
};

export const haveUnreadMessage = (num) => {

    return {
      type: HAVE_UNREAD_MESSAGES,
      payload: num
    };
};

export const updateUnreadMessage = () => {

  return {
    type: UPDATE_UNREAD_MESSAGES,
  };
};

export const allowedHelpDesk = (isAllowed) => {
  return {
    type: ALLOWED_HELP_DESK,
    payload: isAllowed
  };
};

export const typedSearchVin = (vin) => {
  return {
    type: SEARCH_VIN,
    payload: vin
  };
};

export const typedSearchVinAudit = (vin) => {
  return {
    type: SEARCH_VIN_AUDIT,
    payload: vin
  };
};

export const keepPaginationSettings = (paginationSettings) => {
  return {
    type: PAGINATION_SETTINGS,
    payload: paginationSettings
  };
};

export const keepPaginationSettingsAudit = (paginationSettings) => {
  return {
    type: PAGINATION_SETTINGS_AUDIT,
    payload: paginationSettings
  };
};

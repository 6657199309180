
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const SET_EMPTY_USER_PROFILE = 'SET_EMPTY_USER_PROFILE';
export const SET_TRY_GET_USER_PROFILE = 'SET_TRY_GET_USER_PROFILE';
export const REFRESH_DATA_USER_PROFILE = 'REFRESH_DATA_USER_PROFILE';
export const SHOW_MESSAGE_USER_PROFILE = 'SHOW_MESSAGE_USER_PROFILE';
export const HIDE_MESSAGE_USER_PROFILE = 'HIDE_MESSAGE_USER_PROFILE';

export const STATUS_USER_PROFILE = {
    EMPTY: 'EMPTY_STATUS_USER_PROFILE',
    SUCCESS: 'SUCCESS_STATUS_USER_PROFILE',
};



import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))}/>
        <Route path={`${APP_PREFIX_PATH}/analytics`} component={lazy(() => import(`./analytics`))}/>
        <Route path={`${APP_PREFIX_PATH}/shipping`} component={lazy(() => import(`./shipping`))}/>
        <Route path={`${APP_PREFIX_PATH}/last-vehicles`} component={lazy(() => import(`./last-vehicles`))}/>
        <Route path={`${APP_PREFIX_PATH}/shipping-history`} component={lazy(() => import(`./shipping-history`))}/>
        <Route path={`${APP_PREFIX_PATH}/payments`} component={lazy(() => import(`./payment`))}/>
        <Route path={`${APP_PREFIX_PATH}/open-containers`} component={lazy(() => import(`./open-containers`))}/>
        <Route path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./users`))}/>
        <Route path={`${APP_PREFIX_PATH}/libraries`} component={lazy(() => import(`./libraries`))}/>
        <Route path={`${APP_PREFIX_PATH}/requests`} component={lazy(() => import(`./request`))}/>
        <Route path={`${APP_PREFIX_PATH}/tools`} component={lazy(() => import(`./tools`))}/>
        <Route path={`${APP_PREFIX_PATH}/reports`} component={lazy(() => import(`./reports`))}/>
        <Route path={`${APP_PREFIX_PATH}/help-desk`} component={lazy(() => import(`./help-desk`))}/>
        <Route path={`${APP_PREFIX_PATH}/help-desk-audit`} component={lazy(() => import(`./help-desk-audit`))}/>
        <Route path={`${APP_PREFIX_PATH}/accounting`} component={lazy(() => import(`./accounting`))}/>

        <Route path={`${APP_PREFIX_PATH}/auction-log-list`} component={lazy(() => import(`./auction-log-list`))}/>
        <Route path={`${APP_PREFIX_PATH}/purchased-car`} component={lazy(() => import(`./purchased-car`))}/>

        <Route path={`${APP_PREFIX_PATH}/bids`} component={lazy(() => import(`./bids`))}/>

        <Route path={`${APP_PREFIX_PATH}/test`} component={lazy(() => import(`./test`))}/>

        <Route path={`${APP_PREFIX_PATH}/agreements`} component={lazy(() => import(`./agreements`))}/>

        <Route path={`${APP_PREFIX_PATH}/expedition`} component={lazy(() => import(`./expedition`))}/>
        <Route path={`${APP_PREFIX_PATH}/expedition-history`} component={lazy(() => import(`./expedition-history`))}/>

        <Route path={`${APP_PREFIX_PATH}/dispatcher`} component={lazy(() => import(`./dispatcher`))}/>

        <Route path={`${APP_PREFIX_PATH}/extension-log-list`} component={lazy(() => import(`./extension-log-list`))}/>

        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/shipping`}/>
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);
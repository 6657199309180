import {
    SHOW_MESSAGE_USER_PROFILE,
    HIDE_MESSAGE_USER_PROFILE,
    SET_EMPTY_USER_PROFILE,
    SET_TRY_GET_USER_PROFILE,
    GET_USER_PROFILE,
    UPDATE_USER_PROFILE,
    STATUS_USER_PROFILE
} from '../../constants/UserProfile';

const initState = {
    loading: false,
    messageObj: {title: '', message: ''},
    showMessage: false,

    statusUserProfile: STATUS_USER_PROFILE.EMPTY,
    lastTryToken: '',
    successToken: '',
    user: {
        email: ''
    },
    userProfile: {
      firstName: '',
      lastName: '',
      companyName: '',
      phone: '',
      typeUser: null,
      avatar: '',
      locationDefault: '/',
      managerNickname: '',
      managerNicknameAvatar: ''

    }
}

const userProfile = (state = initState, action) => {
    switch (action.type) {
        case UPDATE_USER_PROFILE:
            return {
              ...state,
              userProfile: {
                ...state.userProfile,
                ...action.payload.userProfile
              },
            }
        case GET_USER_PROFILE:
            return {
              ...state,
              statusUserProfile: STATUS_USER_PROFILE.SUCCESS,

              lastTryToken: action.payload.token,
              successToken: action.payload.token,

              user: action.payload.user,
              userProfile: action.payload.userProfile,
            }
        case SET_TRY_GET_USER_PROFILE:
            return {
                ...state,
                lastTryToken: action.payload,
            }
        case SET_EMPTY_USER_PROFILE:
            return {
                ...state,
                statusUserProfile: initState.statusUserProfile,
                lastTryToken: initState.lastTryToken,
                successToken: initState.successToken,
                user: initState.user,
                userProfile: initState.userProfile,
            }
        case SHOW_MESSAGE_USER_PROFILE:
            return {
                ...state,
                messageObj: {title: action.payload.title, message: action.payload.message},
                showMessage: true,
                loading: false
            }
        case HIDE_MESSAGE_USER_PROFILE:
            return {
                ...state,
                messageObj: { ...initState.messageObj },
                showMessage: false,
            }

        default:
            return state;
    }
}


export default userProfile
import React, { useEffect } from "react";
import {Route, Switch, Redirect, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import {IntlProvider} from "react-intl";
import {ConfigProvider} from 'antd';
import {APP_PREFIX_PATH, AUTH_PREFIX_PATH} from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import {refreshDataUserProfile} from "../redux/actions/UserProfile";
import Loading from "../components/shared-components/Loading";
import {STATUS_USER_PROFILE} from '../constants/UserProfile';


function RouteInterceptor({children, isAuthenticated, ...rest}) {
  return (
    <Route
      {...rest}
      render={({location}) =>
        isAuthenticated ? (
          children
              ) : (
                  <Redirect
                      to={{
                        pathname: AUTH_PREFIX_PATH,
                        state: { from: location }
                      }}
                  />
              )
          }
      />
  );
}

export const Views = (props) => {
  const {
    locale, token, location, direction, userProfile,
    refreshDataUserProfile,
  } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);

  useEffect(() => {
    if (token) {
      refreshDataUserProfile(token);
    }
  }, [
    token,
    refreshDataUserProfile,
  ]);


  const getLocation = () => {
    const {userProfile: {userProfile}} = props;

    if (!token) {
      return APP_PREFIX_PATH;
    }

    if (location.pathname === '/' && userProfile?.locationDefault) {
      return userProfile.locationDefault;
    }
    return location.pathname;
  }

  // console.log('getLocation():', getLocation())
  // console.log('userProfile:', userProfile)
  // console.log('token:', token)

  if (token && userProfile.statusUserProfile === STATUS_USER_PROFILE.EMPTY) {
    return <Loading cover="page"/>;
  }

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect to={getLocation()}/>
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
            <AppLayout direction={direction} location={location}/>
          </RouteInterceptor>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth, userProfile }) => {
  const { locale, direction } =  theme;
  const { token } = auth;
  return { locale, token, direction, userProfile }
};
const actionCreators = {
    refreshDataUserProfile
};

export default withRouter(connect(mapStateToProps, actionCreators)(Views));
import React, {useEffect, useState} from 'react';
import {Menu, Dropdown, Badge, Avatar, List, message} from 'antd';
import { 
  MailOutlined, 
  BellOutlined, 
  WarningOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import {Link} from "react-router-dom";

import Flex from 'components/shared-components/Flex'
import HelpDeskService from "../../services/HelpDeskService";
import {showErrors} from "../../services/utils";
import {connect} from "react-redux";
import {haveUnreadMessage} from "../../redux/actions/HelpDesk";

const getIcon =  icon => {
  switch (icon) {
    case 'mail':
      return <MailOutlined />;
    case 'alert':
      return <WarningOutlined />;
    case 'check':
      return <CheckCircleOutlined />
    default:
      return <MailOutlined />;
  }
}

const getNotificationBody = (inList, handleOnClick) => {
  const list = inList.slice(0, 9);
  return list.length > 0 ?
  <List
    size="small"
    itemLayout="horizontal"
    dataSource={list}
    renderItem={item => (
      <List.Item className="list-clickable" >
        <Link
          to={{
            pathname: `/app/help-desk/all-tickets/${item.id}`,
          }}
          style={{ float:"right"}}
          onClick={handleOnClick}
        >
          <Flex alignItems="center">
            <div className="pr-3">
              {item.avatar_requester ? <Avatar src={`${item.avatar_requester}`} /> : <Avatar className={`ant-avatar-mail`} icon={getIcon('mail')} />}

            </div>
            <div className="mr-3">
              <span className="font-weight-bold text-dark">{item.requester} </span>
              <span className="text-gray-light">{item.subject}</span>
            </div>
            {/*<small className="ml-auto">{item.time}</small>*/}
          </Flex>
        </Link>
      </List.Item>
    )}
  />
  :
  <div className="empty-notification">
    <img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="empty" />
    <p className="mt-3">You have viewed all notifications</p>
  </div>;
}

export const NavNotification = (props) => {
  const {haveUnreadMessage} = props;
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([])
  const [needUpdateData, setNeedUpdateData] = useState(0);

  useEffect(() => {
    let cancelled = false;

    let interval = props.allowedHelpDesk && setTimeout(function getHelpDeskNotificationList() {
      HelpDeskService.getHelpDeskNotificationList()
        .then((res) => {
          if (!cancelled) {

            haveUnreadMessage(res.data.length);

            setData(res.data);

          }

        })
        .catch((error) => {
          setData([]);
          const {errorMsg} = showErrors(error);
          message.error(errorMsg);
        });
      interval = setTimeout(getHelpDeskNotificationList, 1 * 60 * 1000);
    }, 1000);

    return () => {
      clearInterval(interval);
      cancelled = true;
    }

  }, [needUpdateData, props.needUpdateUnreadMessages, props.allowedHelpDesk, haveUnreadMessage]);


  const handleVisibleChange = (flag) => {
    setVisible(flag);
  }

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        {/*<Button className="text-primary" type="link" onClick={() => setData([])} size="small">Clear </Button>*/}
      </div>
      <div className="nav-notification-body">
        {getNotificationBody(data,
          () => {
            setVisible(false);
            setNeedUpdateData((prevState) => ++prevState);
          }

        )}
      </div>
      {
        data.length > 9 ?
        <div className="nav-notification-footer">
          <a className="d-block" href="#/">View all</a>
        </div>
        :
        null
      }
    </div>
  );

  return (
    <Dropdown 
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={['click']}
    >
      <Menu mode="horizontal">
        <Menu.Item key="notification">
          <Badge count={data.length}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  )
}

const mapStateToProps = ({ helpDesk }) => {
  const { needUpdateUnreadMessages, allowedHelpDesk } =  helpDesk;
  return { needUpdateUnreadMessages, allowedHelpDesk }
};

const actionCreators = {
  haveUnreadMessage
};

export default connect(mapStateToProps, actionCreators)(NavNotification);

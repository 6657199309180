import {
  HAVE_UNREAD_MESSAGES,
  UPDATE_UNREAD_MESSAGES,
  ALLOWED_HELP_DESK,
  SEARCH_VIN,
  SEARCH_VIN_AUDIT,
  PAGINATION_SETTINGS,
  PAGINATION_SETTINGS_AUDIT
} from '../../constants/HelpDesk';

const initState = {
    countUnreadMessages: 0,
    needUpdateUnreadMessages: 0,
    allowedHelpDesk: false,
    searchVin: '',
    searchVinAudit: '',
    paginationSettings: null,
    paginationSettingsAudit: null
}

const helpDesk = (state = initState, action) => {
    switch (action.type) {
        case HAVE_UNREAD_MESSAGES:
            return {
              ...state,
              countUnreadMessages: action.payload,
            }

        case UPDATE_UNREAD_MESSAGES:
          return {
            ...state,
            needUpdateUnreadMessages: ++state.needUpdateUnreadMessages,
          }

        case ALLOWED_HELP_DESK:
          return {
            ...state,
            allowedHelpDesk: action.payload,
          }

        case SEARCH_VIN:
          return {
            ...state,
            searchVin: action.payload,
          }

        case SEARCH_VIN_AUDIT:
          return {
            ...state,
            searchVinAudit: action.payload,
          }

        case PAGINATION_SETTINGS:
          return {
            ...state,
            paginationSettings: action.payload,
          }

        case PAGINATION_SETTINGS_AUDIT:
          return {
            ...state,
            paginationSettingsAudit: action.payload,
          }

        default:
            return state;
    }
}


export default helpDesk
import fetch from 'auth/FetchInterceptor'
import generateBoundary from "./utils";

const HelpDeskService = {
	_url: '/rest/v1/server/data/helpdesk',


  async _getResource(url) {
    const resp = await fetch({
      url: `${this._url}${url}`,
      method: 'get'
    });
    return resp.data;
  },
  async _setResource(url, {
    method='post',
    data,
    headers = {}}
  ) {
    const resp = await fetch({
      url: `${this._url}${url}`,
      method: method,
      data,
      headers
    });
    return resp.data;
  },
  async _optResource(url) {
    const resp = await fetch({
      url: `${this._url}${url}`,
      method: 'options'
    });
    return resp.data;
  },


  async getHelpDeskList(params) {

    const {
      pageId = null,
      pageSize = 50,
      filters = {},
      search = ''
    } = params;
    let urlParam = '';
    let paramItem = ''
    if (Number.isInteger(pageId)) {
      paramItem = !urlParam ? '?' : '&';
      paramItem += `page=${pageId}`;
      urlParam += paramItem;
    };
    if (Number.isInteger(pageSize)) {
      paramItem = !urlParam ? '?' : '&';
      paramItem += `page_size=${pageSize}`;
      urlParam += paramItem;
    };
    if (search) {
      paramItem = !urlParam ? '?' : '&';
      paramItem += `search=${search}`;
      urlParam += paramItem;
    }

    if (typeof filters === 'object') {
      for (let i in filters) {
        if (filters.hasOwnProperty(i)) {
          paramItem = !urlParam ? '?' : '&';
          paramItem += `${i}=${filters[i]}`;
          urlParam += paramItem;
        }
      }
    }

    const url = `/helpdesk-list${urlParam}`;

    const resp = await this._getResource(url);
    return {
      pageSize: pageSize,
      totalCount: resp.count,
      data: resp.results
    };
  },
  async optHelpDeskList() {
    const url = `/helpdesk-list`;
    const resp = await this._optResource(url);
    return resp.results;
  },

  async getHelpDeskAuditList(params) {

    const {
      pageId = null,
      pageSize = 50,
      filters = {},
      search = ''
    } = params;
    let urlParam = '';
    let paramItem = ''
    if (Number.isInteger(pageId)) {
      paramItem = !urlParam ? '?' : '&';
      paramItem += `page=${pageId}`;
      urlParam += paramItem;
    };
    if (Number.isInteger(pageSize)) {
      paramItem = !urlParam ? '?' : '&';
      paramItem += `page_size=${pageSize}`;
      urlParam += paramItem;
    };
    if (search) {
      paramItem = !urlParam ? '?' : '&';
      paramItem += `search=${search}`;
      urlParam += paramItem;
    }

    if (typeof filters === 'object') {
      for (let i in filters) {
        if (filters.hasOwnProperty(i)) {
          paramItem = !urlParam ? '?' : '&';
          paramItem += `${i}=${filters[i]}`;
          urlParam += paramItem;
        }
      }
    }

    const url = `/helpdesk-audit-list${urlParam}`;

    const resp = await this._getResource(url);
    return {
      pageSize: pageSize,
      totalCount: resp.count,
      data: resp.results
    };
  },
  async optHelpDeskAuditList() {
    const url = `/helpdesk-audit-list`;
    const resp = await this._optResource(url);
    return resp.results;
  },

  async optCreateTicket() {
    const url = `/create-ticket`;
    const resp = await this._optResource(url);
    return resp.results;
  },
  async createTicket(data) {

    const url = `/create-ticket`;
    const resp = await this._setResource(url,
      {
        data,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${generateBoundary()}`,
        },
      });

    return {
      data: resp.results
    };
  },

  async getTicket(id) {

    const url = `/${id}/get-ticket`;
    const resp = await this._getResource(url);

    return {
      data: resp.results
    };
  },
  async getTicketAudit(id) {

    const url = `/${id}/get-ticket-audit`;
    const resp = await this._getResource(url);

    return {
      data: resp.results
    };
  },

  async replyMessage(id, data) {

    const url = `/${id}/reply-message`;
    const resp = await this._setResource(url,
      {
        data,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${generateBoundary()}`,

        },
      });

    return {
      data: resp.result
    };
  },


  async getReassignTicketAssistantsList(id) {
    const url = `/${id}/reassign-ticket-assistants-list`;
    const resp = await this._getResource(url);
    return resp.results;
  },


  async optReassignTicket(id) {
    const url = `/${id}/reassign-ticket`;
    const resp = await this._optResource(url);
    return resp.results;
  },

  async reassignTicket(id, data) {

    const url = `/${id}/reassign-ticket`;
    const resp = await this._setResource(url,
      {
        method: 'patch',
        data,
      });

    return {
      data: resp.result
    };
  },

  async reassignTicketAssistant(id, data) {

    const url = `/${id}/reassign-ticket-to-assistant`;
    const resp = await this._setResource(url,
      {
        method: 'patch',
        data,
      });

    return {
      data: resp.result
    };
  },

  async optChangeStatusTicket(id) {
    const url = `/${id}/change-status-ticket`;
    const resp = await this._optResource(url);
    return resp.results;
  },
  async changeStatusTicket(id, data) {

    const url = `/${id}/change-status-ticket`;
    const resp = await this._setResource(url,
      {
        method: 'patch',
        data,
      });

    return {
      data: resp.result
    };
  },


  async getHelpDeskNotificationList() {
    const url = `/helpdesk-notification-list`;

    const resp = await this._getResource(url);
    return {
      data: resp.results
    };
  },


}
export default HelpDeskService;



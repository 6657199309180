import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  REFRESH_DATA_USER_PROFILE
} from '../../constants/UserProfile';
import {
  getUserProfile,
  setEmptyUserProfile,
  setTryGetUserProfile,
  showMessageUserProfile,
} from "../actions/UserProfile";

import UserProfileService from 'services/UserProfileService';
import navigationConfig, {getNavigationConfig} from "../../configs/NavigationConfig";


function findPathByKey(data, key) {
  let path = "";

  function traverse(obj, currentPath) {
    if (obj.key === key) {
      path = currentPath + obj.path;
      return;
    }

    if (obj.submenu && obj.submenu.length > 0) {
      for (let i = 0; i < obj.submenu.length; i++) {
        traverse(obj.submenu[i], currentPath + obj.path);
      }
    }
  }

  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      traverse(data[i], "");
    }
  }

  return path;
}

function findDeepestPath(data) {
  let deepestPath = "";

  function traverse(obj, currentPath) {
    if (obj.path && obj.path.length > deepestPath.length) {
      deepestPath = obj.path;
    }

    if (obj.submenu && obj.submenu.length > 0) {
      for (let i = 0; i < obj.submenu.length; i++) {
        traverse(obj.submenu[i], currentPath + obj.path);
      }
    }
  }

  if (Array.isArray(data) && data.length > 0) {
    traverse(data[0], "");
  }

  return deepestPath;
}


export function* fetchUserProfile() {
  yield takeEvery(REFRESH_DATA_USER_PROFILE, function* ({payload}) {
    const token = payload;
    try {
      //console.log('Saga token:', token);
      yield put(setEmptyUserProfile());
      yield put(setTryGetUserProfile(token));
      const dataUserProfile = yield call([UserProfileService, UserProfileService.getProfile]);
      //console.log('dataUserProfile:', dataUserProfile);
      const {User, UserProfile} = dataUserProfile;

      const navPermissions = yield call([UserProfileService, UserProfileService.getNavPermissions]);

      const allowedNavigationConfig = getNavigationConfig(navigationConfig, navPermissions.results);

      let path;

      if (Array.isArray(allowedNavigationConfig)) {
        path = findPathByKey(allowedNavigationConfig, 'shippingList');
        if (!path) {
          path = findDeepestPath(allowedNavigationConfig);
        }
      }
      UserProfile.locationDefault = path ? path : '/';

      yield put(getUserProfile(User, UserProfile, token));
    } catch (err) {
			//console.log('Saga err:', err.toString());
			yield put(showMessageUserProfile('Error determining user profile data', err.toString()));
		}
	});
}


export default function* rootSaga() {
  yield all([
		fork(fetchUserProfile)
  ]);
}

import React, {useEffect} from "react";
import {Menu, Dropdown, Avatar, Modal} from "antd";
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { 
  LogoutOutlined,
  EditOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';

import { hideMessageUserProfile } from 'redux/actions/UserProfile';


const menuItem = [
    /*
	{
		title: "Edit Profile",
		icon: EditOutlined ,
		path: "/"
    },
    
    {
		title: "Account Setting",
		icon: SettingOutlined,
		path: "/"
    },
    {
		title: "Billing",
		icon: ShopOutlined ,
		path: "/"
	},
    {
		title: "Help Center",
		icon: QuestionCircleOutlined,
		path: "/"
	}
    */
]

export const NavProfile = ({signOut, hideMessageUserProfile, userProfile}) => {

  useEffect(() => {
    if(userProfile.showMessage) {
      Modal.error({
        title: userProfile.messageObj.title,
        content: userProfile.messageObj.message,
      });
      setTimeout(() => {
        hideMessageUserProfile();
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile.showMessage]);

  const profileImg = userProfile.userProfile.avatar || "/img/profile-avatar-placeholder.png";

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{`${userProfile.userProfile.firstName}`}</h4>
            <span className="text-muted">{`${userProfile.userProfile.lastName}`}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} >
            <Link to={'/app/users/edit-profile'} >
              <EditOutlined className="mr-3"/>
              <span className="font-weight-normal">Edit Profile</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={menuItem.length + 2} onClick={e => signOut()}>
            <span>
              <LogoutOutlined className="mr-3"/>
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

const mapStateToProps = ({ userProfile }) => {
  return { userProfile }
};

export default connect(mapStateToProps, {signOut, hideMessageUserProfile })(NavProfile)
